import React from 'react';

import './HelpModule.css'

const HelpModule = (props) => {

	const nullScroll = (evt) => {
		evt.preventDefault()
		window.scrollTop = document.querySelector("#HelpModule").dataset.scrollPoint
	}
	
	const showHelp = () => {
		document.querySelector("#help-content").classList.add("show")
		document.querySelector("#HelpModule").dataset.scrollPoint = window.scrollY
		document.addEventListener('scroll', nullScroll)
	}

	const hideHelp = () => {
		document.querySelector("#help-content").scrollTop = 0
		document.querySelector("#help-content").classList.remove("show")
		delete document.querySelector("#HelpModule").dataset.scrollPoint
		document.removeEventListener('scroll', nullScroll)
	}

	return (
		<div id="HelpModule">
			<div id="help-link-wrapper">
				<button id="help-link" onClick={showHelp}>Help and Tips</button>
			</div>
			<div id="help-content">
				<p>This app is for testing your knowledge of Ireland's counties&nbsp;&mdash; where they are in relation to each other, what they're known for, etc.</p>
				<p>Scroll down to the checkboxes to choose what types of questions you want, or restrict to certain provinces.</p>
				<p><strong>Tips:</strong></p>
				<ul>
					<li>If you're just starting to learn the counties, try just one province. Connaught & Munster are easiest, with the fewest counties.</li>
					<li>The "Which county is in [province]?" mode is another easy beginner option.</li>
					<li>As you learn, toggle on more of the "questions of these types" options.</li>
				</ul>
				<p>Naturally, the "Which county has [city] in it?" mode skips cities whose names match their counties. It's obvious what county the city of Galway is in!</p>
				<p><strong>Privacy:</strong></p>
				<p>Your preferences are only stored on your own device, using the browser's "local storage" feature. Your data is never shared with anyone, not even this app's creator.</p>
				<p><button id="close-help" onClick={hideHelp}>Close</button></p>
			</div>
		</div>
	)
}

export default HelpModule
