import React from 'react';

import './ModeSelector.css'

const ModeSelector = ({state, questionStructure, handleCheckboxClick, handleButtonClick}) => {

	const variablesToSquareBrackets = str => {
		/* eslint-disable dot-location */
		return str.replace('County $[[county]]', '[county]').
			replace(/([a-z])([A-Z])/g, (full, m1, m2) => m1 + " " + m2.toLowerCase()).
			replace(/([a-z])_([a-z])/g, (full, m1, m2) => m1 + " " + m2.toLowerCase()).
			replace(/\$\[\[([a-z ]+)\]\]/g, "[$1]")
		/* eslint-enable dot-location */
	}
	
	const ProvinceCheckboxes = Object.keys(state.status.provinces).sort().map(province => {
		return (
			<div key={province} className="check-box-container"><label htmlFor={"province-" + province}><input type="checkbox" id={"province-" + province} checked={state.status.provinces[province]} onChange={handleCheckboxClick} />&nbsp;{province}</label></div>
		)
	})
	
	const QuestionCheckboxes = questionStructure.map(q => {
		return (
			<div key={q.method} className="check-box-container"><label htmlFor={"question-" + q.method}><input type="checkbox" id={"question-" + q.method} checked={state.status.questions[q.method] || false} onChange={handleCheckboxClick} />&nbsp;{variablesToSquareBrackets(q.text)}</label></div>
		)
	})
	
	return (
		<div id="ModeSelector">
			<div id="toggle-container">
				<button id="toggle" className="toggle" onClick={handleButtonClick}>{state.buttonText}</button>
			</div>
			<br />
			<div id="province-selector">
				<fieldset>
					<legend>Only target counties in provinces:</legend>
					{ProvinceCheckboxes}
				</fieldset>
			</div>
			<div id="question-selector">
				<fieldset>
					<legend>Only ask questions of these types:</legend>
					{QuestionCheckboxes}
				</fieldset>
			</div>
		</div>
	)
}

export default ModeSelector
