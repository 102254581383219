import React, { useState } from 'react'

import './MapOfIreland.css'




const MapOfIreland = ({ county = {name: "none"}, correct, handleClick}) => {
	
	const coords = {
		Antrim: "282, 21, 293, 75, 307, 73, 301, 100, 322, 94, 325, 74, 335, 69, 303, 22",
		Armagh: "282, 106, 268, 123, 272, 133, 282, 140, 284, 150, 294, 148, 289, 134, 290, 125, 294, 107",
		Carlow: "290, 295, 277, 295, 278, 315, 265, 335, 256, 302, 276, 290",
		Cavan: "268, 173, 247, 155, 221, 150, 202, 140, 196, 140, 189, 129, 184, 137, 209, 151, 216, 163, 218, 174, 229, 182, 248, 183, 252, 183, 252, 176",
		Clare: "150, 299, 149, 310, 124, 310, 117, 300, 97, 320, 78, 315, 55, 326, 77, 309, 86, 302, 92, 290, 92, 284, 89, 283, 96, 266, 103, 265, 111, 265, 121, 279, 132, 282, 140, 278, 151, 282, 158, 282",
		Cork: "170, 378, 170, 374, 168, 366, 157, 364, 145, 364, 130, 353, 113, 362, 109, 360, 101, 366, 105, 378, 100, 389, 106, 395, 96, 404, 90, 414, 59, 427, 51, 433, 45, 426, 36, 437, 63, 427, 77, 423, 82, 429, 69, 438, 72, 442, 56, 454, 65, 450, 75, 445, 82, 442, 84, 451, 95, 449, 98, 446, 105, 442, 115, 444, 126, 440, 142, 435, 154, 422, 155, 429, 155, 419, 153, 410, 161, 404, 167, 405, 170, 405, 170, 415, 170, 416, 176, 414, 181, 409, 190, 402",
		Derry: "257, 28, 276, 26, 284, 73, 272, 79, 263, 70, 261, 60, 231, 43, 256, 40",
		Donegal: "206, 31, 187, 26, 169, 33, 163, 54, 164, 67, 156, 76, 143, 74, 138, 82, 157, 88, 180, 82, 170, 104, 177, 104, 182, 94, 192, 96, 196, 93, 189, 87, 189, 74, 198, 71, 208, 72, 219, 54, 227, 32, 233, 32, 247, 16, 240, 12, 232, 6, 233, 13, 221, 16, 224, 32, 214, 48, 206, 50, 214, 27, 212, 26",
		Down: "303, 109, 332, 96, 334, 85, 348, 82, 359, 107, 357, 114, 354, 106, 347, 90, 340, 93, 340, 116, 341, 121, 349, 120, 345, 126, 329, 127, 326, 147, 322, 151, 305, 145, 298, 134, 299, 116, 303, 113",
		Dublin: "305, 201, 314, 211, 313, 249, 299, 249, 294, 246, 295, 233, 303, 225, 298, 212, 304, 210, 306, 210",
		Fermanagh: "202, 93, 180, 110, 201, 134, 214, 136, 220, 141, 228, 141, 233, 143, 240, 132, 240, 126, 208, 113, 207, 107, 214, 101",
		Galway: "141, 271, 157, 278, 169, 263, 183, 253, 180, 251, 170, 247, 158, 212, 147, 198, 122, 208, 111, 222, 115, 227, 115, 239, 107, 243, 103, 234, 79, 219, 85, 217, 64, 207, 57, 208, 50, 208, 42, 215, 44, 223, 47, 231, 55, 227, 61, 230, 58, 238, 63, 240, 68, 231, 75, 238, 76, 244, 85, 247, 105, 247, 105, 244, 105, 241, 113, 244, 120, 248, 126, 253, 121, 260, 119, 264, 127, 276",
		Kerry: "87, 347, 88, 333, 79, 332, 73, 334, 70, 342, 55, 346, 56, 356, 62, 360, 63, 371, 40, 366, 29, 366, 22, 366, 14, 377, 58, 375, 52, 383, 24, 399, 21, 408, 20, 411, 28, 407, 32, 412, 32, 416, 52, 412, 69, 404, 70, 412, 53, 425, 77, 413, 86, 412, 90, 407, 92, 392, 96, 394, 94, 382, 92, 372, 93, 361",
		Kildare: "253, 233, 258, 231, 268, 234, 276, 233, 284, 237, 285, 254, 276, 267, 268, 276, 268, 290, 265, 290, 262, 282, 257, 277, 254, 261, 258, 246",
		Kilkenny: "240, 294, 248, 300, 251, 315, 258, 346, 252, 360, 237, 357, 231, 336, 226, 316, 214, 305, 227, 305",
		Laois: "245, 264, 254, 285, 258, 295, 254, 295, 246, 287, 231, 290, 227, 297, 216, 295, 211, 297, 211, 281, 218, 271, 219, 263, 232, 259",
		Leitrim: "196, 152, 208, 159, 208, 164, 199, 173, 193, 179, 189, 168, 184, 165, 183, 151, 181, 146, 175, 146, 168, 139, 163, 120, 161, 110, 171, 109, 179, 120, 185, 125, 179, 133, 179, 140, 189, 146",
		Limerick: "155, 338, 166, 329, 167, 321, 158, 315, 145, 318, 122, 322, 96, 330, 94, 351, 109, 355, 122, 353, 128, 342, 150, 357, 169, 356, 159, 347, 152, 344",
		Longford: "208, 172, 227, 190, 210, 199, 208, 210, 202, 212, 190, 208, 188, 202, 191, 189, 196, 182",
		Louth: "310, 158, 303, 151, 288, 155, 284, 165, 279, 172, 288, 186, 297, 190, 305, 183, 305, 177, 296, 167, 296, 159",
		Mayo: "106, 143, 99, 128, 68, 124, 67, 132, 56, 136, 51, 126, 41, 145, 48, 123, 56, 139, 53, 149, 60, 144, 59, 153, 63, 162, 66, 167, 58, 171, 79, 170, 78, 177, 80, 186, 57, 190, 57, 201, 71, 202, 86, 208, 106, 219, 113, 205, 122, 198, 129, 195, 134, 189, 131, 175, 135, 173, 138, 163, 125, 166, 116, 162, 108, 155, 114, 148, 101, 145",
		Meath: "258, 180, 273, 176, 285, 194, 299, 195, 303, 195, 303, 203, 292, 203, 290, 215, 293, 223, 287, 228, 273, 223, 269, 228, 257, 221, 251, 228, 259, 205, 260, 198, 245, 195, 242, 190, 261, 190, 262, 182, 274, 182, 286, 195, 284, 194, 285, 193, 279, 185, 294, 195, 297, 196, 291, 200",
		Monaghan: "253, 118, 265, 137, 275, 145, 277, 162, 272, 168, 257, 151, 247, 145, 240, 146, 247, 135, 244, 126",
		Offaly: "227, 253, 205, 258, 211, 266, 198, 278, 190, 289, 189, 283, 195, 268, 185, 259, 190, 252, 185, 245, 190, 242, 211, 239, 226, 244, 242, 235, 248, 235, 252, 243, 244, 258, 239, 258",
		Roscommon: "157, 202, 168, 217, 172, 235, 177, 243, 184, 234, 179, 212, 180, 197, 184, 187, 183, 176, 176, 166, 176, 151, 154, 169, 151, 171, 141, 167, 145, 176, 138, 181, 140, 192, 133, 199, 146, 193",
		Sligo: "154, 115, 158, 143, 167, 150, 154, 162, 148, 165, 145, 158, 135, 155, 131, 159, 119, 154, 124, 145, 113, 139, 113, 132, 132, 133, 150, 139, 150, 132, 151, 120, 146, 120",
		Tipperary: "207, 306, 221, 326, 225, 347, 227, 350, 203, 350, 199, 366, 187, 363, 181, 366, 177, 360, 176, 349, 164, 342, 171, 334, 175, 319, 170, 311, 157, 310, 158, 298, 165, 293, 170, 284, 175, 275, 177, 265, 185, 268, 183, 280, 181, 290, 189, 300, 201, 287",
		Tyrone: "227, 55, 248, 69, 254, 79, 278, 89, 269, 108, 264, 113, 252, 110, 242, 112, 238, 119, 217, 106, 223, 100, 196, 82, 216, 79",
		Waterford: "182, 378, 195, 394, 203, 398, 210, 392, 211, 381, 224, 378, 241, 375, 246, 371, 252, 374, 256, 372, 252, 367, 239, 365, 227, 360, 207, 359, 208, 366, 197, 372, 187, 372",
		Westmeath: "231, 188, 236, 201, 250, 206, 248, 216, 233, 233, 225, 237, 216, 231, 197, 236, 192, 233, 191, 228, 195, 226, 195, 221, 202, 221, 211, 218, 220, 201",
		Wexford: "313, 307, 306, 319, 308, 333, 296, 353, 295, 362, 302, 367, 278, 367, 273, 362, 267, 369, 260, 360, 265, 350, 286, 320, 304, 306",
		Wicklow: "292, 253, 309, 257, 315, 255, 321, 273, 315, 301, 303, 297, 291, 312, 284, 303, 296, 294, 277, 283, 279, 277, 292, 259, 309, 259, 309, 258, 295, 258",
	}
	
	const SingleArea = name => {
		if (name === correct) {
			return (
				<area
					key={name}
					shape="polygon"
					coords={coords[name]}
					id={"area-" + name}
					alt={"County " + name}
					onClick={(evt) => {handleClick(evt)}}
					className="correct"
				/>
			)
		} else {
			return (
				<area
					key={name}
					shape="polygon"
					coords={coords[name]}
					id={"area-" + name}
					alt={"County " + name}
					onClick={(evt) => {handleClick(evt)}}
				/>
			)
		}
	}
	
	let [mapImageLoaded, setMapImageLoaded] = useState(false)
	
	const updateSpinner = () => {
		mapImageLoaded = true
		setMapImageLoaded(true)
	}
	
	const renderLoadingSpinner = () => {
		if (mapImageLoaded) {
			return (
				<div id="spinner-container">
					<div>
						Map load complete
					</div>
				</div>
			)
		} else {
			return (
				<div id="spinner-container">
					<div>
						<div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
						<br />
						Loading map of Ireland...
					</div>
				</div>
			)
		}
	}
	let fileName = 'images/county-'
	
	if (county.name === 'none' || county.name === 'all') {
		fileName += county.name + '.gif'
	} else {
		fileName += county.name + '-' + county.color + '.gif'
	}
	fileName = fileName.toLowerCase()
	
	const hide = county.name === "hide" ? "hide" : ""
	
	let MapAreas = []
	
	Object.keys(coords).forEach(county => {
		MapAreas.push(SingleArea(county))
	})
	
	// preload the images
	let image0 = new Image()
	image0.src = 'images/county-none.gif'
	Object.keys(coords).map(county => {
		let image1 = new Image()
		image1.src = 'images/county-' + county.toLowerCase() + '-red.gif'
		let image2 = new Image()
		image2.src = 'images/county-' + county.toLowerCase() + '-green.gif'
		return [image1, image2]
	})
	
	return (
		<div id="MapOfIreland" className={hide}>
			{renderLoadingSpinner()}
			<div id="map-container">
				<img src={fileName} id="map-image" alt="map of Ireland" useMap="#county-coordinates" onLoad={updateSpinner} />
			</div>
			
			<map name="county-coordinates" id="county-coordinates">
					{MapAreas}
			</map>
		</div>
	)
}

export default MapOfIreland
