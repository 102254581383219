import React from 'react';

import Message from './Message'

import './PickList.css'

const PickList = ({state, handleClick}) => {
	const message = state.message
	const items = state.listItems
	const ListItems = items.map((question, i) => {
		return <li key={question.text}>
			<a href="/"
				key={i}
				className={question.state}
				id={"pick-" + question.text}
				onClick={(evt) => {handleClick(evt)}}
			>{question.text}</a></li>
	})
	
	return (
		<div id="PickList" className={"c" + items.length}>
			<ol>
				{ListItems}
			</ol>
			<Message text={message.text} type={message.type} />
		</div>
	)
}

export default PickList
