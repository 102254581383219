import React from 'react';

import MapOfIreland from './MapOfIreland'
import PickList from './PickList'
import ModeSelector from './ModeSelector'
import HelpModule from './HelpModule'

import './App.css';


const countyData = [
	{
		name: "Antrim",
		irishName: "Aontroim",
		province: "Ulster",
		majorCities: ["Ballymena"],
		borderedBy: [
			{
				name: 'the Atlantic Ocean',
				direction: 'north'
			},
			{
				name: 'the North Channel',
				direction: 'east'
			},
			{
				name: 'County Down',
				direction: 'south'
			},
			{
				name: 'County Derry',
				direction: 'west'
			},
		],
		knownFor: ["the Giant's Causeway"],
	},
	{
		name: "Armagh",
		irishName: "Ard Macha",
		province: "Ulster",
		majorCities: [],
		borderedBy: [
			// {
			//  name: 'SOMEPLACE',
			//  direction: 'north'
			// },
			{
				name: 'County Down',
				direction: 'east'
			},
			{
				name: 'County Louth',
				direction: 'south'
			},
			{
				name: 'County Monaghan',
				direction: 'southwest'
			},
			{
				name: 'County Tyrone',
				direction: 'northwest'
			},
		],
		knownFor: ["its many orchards", "Emain Macha", "Slieve Gullion", "Navan Fort"],
	},
	{
		name: "Carlow",
		irishName: "Ceatharlach",
		province: "Leinster",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Kildare',
				direction: 'north'
			},
			{
				name: 'County Wicklow',
				direction: 'northeast'
			},
			{
				name: 'County Wexford',
				direction: 'southeast'
			},
			{
				name: 'County Kilkenny',
				direction: 'southwest'
			},
			{
				name: 'County Laois',
				direction: 'northwest'
			},
		],
		knownFor: ["the Brownshill Dolmen"],
	},
	{
		name: "Cavan",
		irishName: "An Cabhán",
		province: "Ulster",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Fermanagh',
				direction: 'north'
			},
			{
				name: 'County Monaghan',
				direction: 'northeast'
			},
			{
				name: 'County Louth',
				direction: 'east'
			},
			{
				name: 'County Meath',
				direction: 'southeast'
			},
			{
				name: 'County Westmeath',
				direction: 'south'
			},
			{
				name: 'County Longford',
				direction: 'southwest'
			},
			{
				name: 'County Leitrim',
				direction: 'west'
			},
		],
		knownFor: ["the Shannon Pot (source of the River Shannon)"],
	},
	{
		name: "Clare",
		irishName: "An Clár",
		province: "Munster",
		majorCities: ["Ennis", "Shannon"],
		borderedBy: [
			{
				name: 'County Galway',
				direction: 'north'
			},
			{
				name: 'County Tipperary',
				direction: 'east'
			},
			{
				name: 'County Limerick',
				direction: 'south'
			},
			{
				name: 'the Atlantic Ocean',
				direction: 'west'
			},
		],
		knownFor: ["the Burren", "the Cliffs of Moher"],
	},
	{
		name: "Cork",
		irishName: "Corcaigh",
		province: "Munster",
		majorCities: ["Bantry", "Kinsale"],
		borderedBy: [
			{
				name: 'County Limerick',
				direction: 'north'
			},
			{
				name: 'County Tipperary',
				direction: 'northeast'
			},
			{
				name: 'County Waterford',
				direction: 'east'
			},
			{
				name: 'the Celtic Sea',
				direction: 'south'
			},
			{
				name: 'County Kerry',
				direction: 'west'
			},
		],
		knownFor: ["Fastnet Rock (\"Ireland's Teardrop\")", "Kinsale", "the Blarney Stone"],
	},
	{
		name: "Derry",
		irishName: "Doire",
		province: "Ulster",
		majorCities: [],
		borderedBy: [
			{
				name: 'the Atlantic Ocean',
				direction: 'north'
			},
			{
				name: 'County Antrim',
				direction: 'east'
			},
			{
				name: 'County Tyrone',
				direction: 'southwest'
			},
			{
				name: 'County Donegal',
				direction: 'west'
			},
		],
		knownFor: [],// please, why can't I find anything good?
	},
	{
		name: "Donegal",
		irishName: "Dún na nGall",
		province: "Ulster",
		majorCities: ["Letterkenny"],
		borderedBy: [
			{
				name: 'the Atlantic Ocean',
				direction: 'north'
			},
			{
				name: 'County Derry',
				direction: 'east'
			},
			{
				name: 'County Tyrone',
				direction: 'southeast'
			},
			{
				name: 'County Fermanagh',
				direction: 'southeast'
			},
			{
				name: 'County Leitrim',
				direction: 'south'
			},
			{
				name: 'the Atlantic Ocean',
				direction: 'west'
			},
		],
		knownFor: ["Malin Head", "Slieve League", "its large Gaeltacht region (by area)"],
	},
	{
		name: "Down",
		irishName: "An Dúin",
		province: "Ulster",
		majorCities: ["Banbridge town", "Bangor", "Newry"],
		borderedBy: [
			{
				name: 'County Antrim',
				direction: 'north'
			},
			{
				name: 'the Irish Sea',
				direction: 'east'
			},
			{
				name: 'County Louth',
				direction: 'south'
			},
			{
				name: 'County Armagh',
				direction: 'west'
			},
		],
		knownFor: ["being the home of the beautiful maid with the nut-brown hair"],
	},
	{
		name: "Dublin",
		irishName: "Baile Átha Cliath",
		province: "Leinster",
		majorCities: ["Balbriggan", "Swords"],
		borderedBy: [
			{
				name: 'County Meath',
				direction: 'northwest'
			},
			{
				name: 'the Irish Sea',
				direction: 'east'
			},
			{
				name: 'County Wicklow',
				direction: 'south'
			},
			{
				name: 'County Kildare',
				direction: 'southwest'
			},
		],
		knownFor: ["Kilmainham Gaol"],
	},
	{
		name: "Fermanagh",
		irishName: "Fir Manach",
		province: "Ulster",
		majorCities: ["Enniskillen"],
		borderedBy: [
			{
				name: 'County Tyrone',
				direction: 'northeast'
			},
			{
				name: 'County Monaghan',
				direction: 'southeast'
			},
			{
				name: 'County Cavan',
				direction: 'south'
			},
			{
				name: 'County Leitrim',
				direction: 'west'
			},
			{
				name: 'County Donegal',
				direction: 'northwest'
			},
		],
		knownFor: ["being the birthplace of both Wilde and Beckett", "the Marble Arch Caves", "its recreational boating", "Lough Erne"],
	},
	{
		name: "Galway",
		irishName: "Gaillimh",
		province: "Connaught",
		majorCities: ["Tuam"],
		borderedBy: [
			{
				name: 'County Mayo',
				direction: 'north'
			},
			{
				name: 'County Roscommon',
				direction: 'northeast'
			},
			{
				name: 'County Offaly',
				direction: 'east'
			},
			{
				name: 'County Tipperary',
				direction: 'southeast'
			},
			{
				name: 'County Clare',
				direction: 'south'
			},
			{
				name: 'the Atlantic Ocean',
				direction: 'west'
			},
		],
		knownFor: ["Dun Aengus", "the Aran Islands", "its large Gaeltacht region (by population)"],
	},
	{
		name: "Kerry",
		irishName: "Ciarraí",
		province: "Munster",
		majorCities: ["Dingle", "Killarney", "Tralee"],
		borderedBy: [
			{
				name: 'County Clare',
				direction: 'northeast'
			},
			{
				name: 'County Limerick',
				direction: 'east'
			},
			{
				name: 'County Cork',
				direction: 'southeast'
			},
			{
				name: 'the Atlantic Ocean',
				direction: 'west'
			},
		],
		knownFor: ["Skellig Michael"],
	},
	{
		name: "Kildare",
		irishName: "Cill Dara",
		province: "Leinster",
		majorCities: ["Celbridge", "Newbridge", "Naas"],
		borderedBy: [
			{
				name: 'County Meath',
				direction: 'north'
			},
			{
				name: 'County Dublin',
				direction: 'northeast'
			},
			{
				name: 'County Wicklow',
				direction: 'southeast'
			},
			{
				name: 'County Carlow',
				direction: 'south'
			},
			{
				name: 'County Laois',
				direction: 'southwest'
			},
			{
				name: 'County Offaly',
				direction: 'west'
			},
		],
		knownFor: ["its horse racing and stud farms", "having once had a monastery of St. Brigid", "Dún Ailinne (aka Dunaulin or Knockaulin)"],
	},
	{
		name: "Kilkenny",
		irishName: "Cill Chainnigh",
		province: "Leinster",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Laois',
				direction: 'north'
			},
			{
				name: 'County Carlow',
				direction: 'east'
			},
			{
				name: 'County Wexford',
				direction: 'southeast'
			},
			{
				name: 'County Waterford',
				direction: 'south'
			},
			{
				name: 'County Tipperary',
				direction: 'west'
			},
		],
		knownFor: ["its quarreling cats"],
	},
	{
		name: "Laois",
		irishName: "Laois",
		province: "Leinster",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Offaly',
				direction: 'northwest'
			},
			{
				name: 'County Kildare',
				direction: 'east'
			},
			{
				name: 'County Carlow',
				direction: 'southeast'
			},
			{
				name: 'County Kilkenny',
				direction: 'south'
			},
			{
				name: 'County Tipperary',
				direction: 'southwest'
			},
		],
		knownFor: ["the Rock of Dunamase", "the discovery site of Cashel Man"],
	},
	{
		name: "Leitrim",
		irishName: "Liatroim",
		province: "Connaught",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Donegal',
				direction: 'north'
			},
			{
				name: 'County Fermanagh',
				direction: 'northeast'
			},
			{
				name: 'County Cavan',
				direction: 'east'
			},
			{
				name: 'County Longford',
				direction: 'south'
			},
			{
				name: 'County Roscommon',
				direction: 'southwest'
			},
			{
				name: 'County Sligo',
				direction: 'west'
			},
		],
		knownFor: [],// being so rural there's only one traffic light in the whole damned county?
	},
	{
		name: "Limerick",
		irishName: "Luimneach",
		province: "Munster",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Clare',
				direction: 'north'
			},
			{
				name: 'County Tipperary',
				direction: 'east'
			},
			{
				name: 'County Cork',
				direction: 'south'
			},
			{
				name: 'County Kerry',
				direction: 'west'
			},
		],
		knownFor: ["Lough Gur", "the Grange Stone Circle"],
	},
	{
		name: "Longford",
		irishName: "An Longfort",
		province: "Leinster",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Leitrim',
				direction: 'north'
			},
			{
				name: 'County Cavan',
				direction: 'northeast'
			},
			{
				name: 'County Westmeath',
				direction: 'southeast'
			},
			{
				name: 'County Roscommon',
				direction: 'west'
			},
		],
		knownFor: [],// fuck-all, TBPH
	},
	{
		name: "Louth",
		irishName: "Lú",
		province: "Leinster",
		majorCities: ["Drogheda", "Dundalk"],
		borderedBy: [
			{
				name: 'County Armagh',
				direction: 'north'
			},
			{
				name: 'County Down',
				direction: 'north'
			},
			{
				name: 'the Irish Sea',
				direction: 'east'
			},
			{
				name: 'County Meath',
				direction: 'south'
			},
			{
				name: 'County Cavan',
				direction: 'west'
			},
			{
				name: 'County Monaghan',
				direction: 'northwest'
			},
		],
		knownFor: [],// squat that I can find except being small
	},
	{
		name: "Mayo",
		irishName: "Maigh Eo",
		province: "Connaught",
		majorCities: ["Ballina", "Castlebar", "Westport"],
		borderedBy: [
			{
				name: 'the Atlantic Ocean',
				direction: 'north'
			},
			{
				name: 'County Sligo',
				direction: 'northeast'
			},
			{
				name: 'County Roscommon',
				direction: 'east'
			},
			{
				name: 'County Galway',
				direction: 'south'
			},
			{
				name: 'the Atlantic Ocean',
				direction: 'west'
			},
		],
		knownFor: ["its renewable wind & wave energy resources", "Céide Fields", "being the site of Dún Flidhais in legend", "being the birthplace of Pirate Queen Grace O'Malley"],
	},
	{
		name: "Meath",
		irishName: "An Mhí",
		province: "Leinster",
		majorCities: ["Kells", "Navan"],
		borderedBy: [
			{
				name: 'County Louth',
				direction: 'northeast'
			},
			{
				name: 'the Irish Sea',
				direction: 'north'
			},
			{
				name: 'County Dublin',
				direction: 'southeast'
			},
			{
				name: 'County Kildare',
				direction: 'south'
			},
			{
				name: 'County Offaly',
				direction: 'southwest'
			},
			{
				name: 'County Westmeath',
				direction: 'west'
			},
			{
				name: 'County Cavan',
				direction: 'northwest'
			},
		],
		knownFor: ["Brú na Bóinne", "Newgrange", "the Hill of Tara"],
	},
	{
		name: "Monaghan",
		irishName: "Muineachán",
		province: "Ulster",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Tyrone',
				direction: 'north'
			},
			{
				name: 'County Armagh',
				direction: 'northeast'
			},
			{
				name: 'County Louth',
				direction: 'southeast'
			},
			{
				name: 'County Cavan',
				direction: 'southwest'
			},
			{
				name: 'County Fermanagh',
				direction: 'west'
			},
		],
		knownFor: ["being the birthplace of poet Patrick Kavanagh"],
	},
	{
		name: "Offaly",
		irishName: "Uíbh Fhailí",
		province: "Leinster",
		majorCities: ["Tullamore"],
		borderedBy: [
			{
				name: 'County Westmeath',
				direction: 'north'
			},
			{
				name: 'County Meath',
				direction: 'northeast'
			},
			{
				name: 'County Kildare',
				direction: 'east'
			},
			{
				name: 'County Laois',
				direction: 'southeast'
			},
			{
				name: 'County Tipperary',
				direction: 'southwest'
			},
			{
				name: 'County Galway',
				direction: 'west'
			},
			{
				name: 'County Roscommon',
				direction: 'northwest'
			},
		],
		knownFor: ["Clonmacnoise Monastery", "Croghan Hill (discovery site of Old Croghan Man)", "being the origin of Tullamore DEW"],
	},
	{
		name: "Roscommon",
		irishName: "Ros Comáin",
		province: "Connaught",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Sligo',
				direction: 'northwest'
			},
			{
				name: 'County Leitrim',
				direction: 'northeast'
			},
			{
				name: 'County Longford',
				direction: 'east'
			},
			{
				name: 'County Westmeath',
				direction: 'southeast'
			},
			{
				name: 'County Offaly',
				direction: 'southeast'
			},
			{
				name: 'County Galway',
				direction: 'southwest'
			},
			{
				name: 'County Mayo',
				direction: 'west'
			},
		],
		knownFor: ["Rathcroghan, legendary court of Queen Maeve"],
	},
	{
		name: "Sligo",
		irishName: "Sligeach",
		province: "Connaught",
		majorCities: [],
		borderedBy: [
			{
				name: 'the Atlantic Ocean',
				direction: 'north'
			},
			{
				name: 'County Leitrim',
				direction: 'east'
			},
			{
				name: 'County Roscommon',
				direction: 'southeast'
			},
			{
				name: 'County Mayo',
				direction: 'southwest'
			},
		],
		knownFor: ["Ben Bulben", "being Yeats' childhood home"],
	},
	{
		name: "Tipperary",
		irishName: "Tiobraid Árann",
		province: "Munster",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Offaly',
				direction: 'northeast'
			},
			{
				name: 'County Laois',
				direction: 'east'
			},
			{
				name: 'County Kilkenny',
				direction: 'east'
			},
			{
				name: 'County Waterford',
				direction: 'south'
			},
			{
				name: 'County Cork',
				direction: 'southwest'
			},
			{
				name: 'County Limerick',
				direction: 'west'
			},
			{
				name: 'County Clare',
				direction: 'northwest'
			},
			{
				name: 'County Galway',
				direction: 'northwest'
			},
		],
		knownFor: ["the Rock of Cashel"],
	},
	{
		name: "Tyrone",
		irishName: "Tír Eoghain",
		province: "Ulster",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Derry',
				direction: 'northeast'
			},
			{
				name: 'County Armagh',
				direction: 'southeast'
			},
			{
				name: 'County Monaghan',
				direction: 'south'
			},
			{
				name: 'County Fermanagh',
				direction: 'southwest'
			},
			{
				name: 'County Donegal',
				direction: 'northwest'
			},
		],
		knownFor: [],// I got nothin'
	},
	{
		name: "Waterford",
		irishName: "Port Láirge",
		province: "Munster",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Tipperary',
				direction: 'north'
			},
			{
				name: 'County Kilkenny',
				direction: 'northeast'
			},
			{
				name: 'County Wexford',
				direction: 'east'
			},
			{
				name: 'the Celtic Sea',
				direction: 'south'
			},
			{
				name: 'County Cork',
				direction: 'west'
			},
		],
		knownFor: ["its fine crystal and glass"],
	},
	{
		name: "Westmeath",
		irishName: "An Iarmhí",
		province: "Leinster",
		majorCities: ["Athlone", "Mullingar"],
		borderedBy: [
			{
				name: 'County Cavan',
				direction: 'north'
			},
			{
				name: 'County Meath',
				direction: 'east'
			},
			{
				name: 'County Offaly',
				direction: 'south'
			},
			{
				name: 'County Roscommon',
				direction: 'west'
			},
			{
				name: 'County Longford',
				direction: 'northwest'
			},
		],
		knownFor: ["the Hill of Uisneach"],
	},
	{
		name: "Wexford",
		irishName: "Loch Garman",
		province: "Leinster",
		majorCities: [],
		borderedBy: [
			{
				name: 'County Wicklow',
				direction: 'north'
			},
			{
				name: 'the Irish Sea',
				direction: 'east'
			},
			{
				name: 'the Celtic Sea',
				direction: 'south'
			},
			{
				name: 'County Waterford',
				direction: 'west'
			},
			{
				name: 'County Kilkenny',
				direction: 'west'
			},
			{
				name: 'County Carlow',
				direction: 'northwest'
			},
		],
		knownFor: ["Vinegar Hill (site of the famed battle)", "Enniscorthy Castle", "its sunny weather (compared with the rest of Ireland, anyway)"],
	},
	{
		name: "Wicklow",
		irishName: "Cill Mhantáin",
		province: "Leinster",
		majorCities: ["Greystones"],
		borderedBy: [
			{
				name: 'County Dublin',
				direction: 'north'
			},
			{
				name: 'the Irish Sea',
				direction: 'east'
			},
			{
				name: 'County Wexford',
				direction: 'south'
			},
			{
				name: 'County Carlow',
				direction: 'southwest'
			},
			{
				name: 'County Kildare',
				direction: 'west'
			},
		],
		knownFor: ["Glendalough", "Powerscourt Estate"],
	},
]

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedCounty: {
				name: 'all',
				color: '',
			},
			prevCounty: '',
			prevIndex: 0,
			targetCounty: '',
			message: {
				text: 'this is a message',
				type: ''
			},
			question: "",
			questionMode: "", // options: pickOnMap, 4fromMap, 4textOnly
			listItems: [],
			mode: "study",
			buttonText: "Start Quiz",
			status: {
				provinces: {
					Connaught: true,
					Leinster: true,
					Munster: true,
					Ulster: true
				},
				questions: {
					askForCountyByMap: true,
					askForMapPickByCounty: true,
					askForCountyByProvince: false,
					askForCountyByIrishName: false,
					askForCountyByBorderingItem: false,
					askForCountyByCity: false,
					askForCountyByAttraction: false
				}
			}
		};
		let checkboxState = JSON.parse(localStorage.getItem("checkboxState"))
		if (checkboxState) {
			this.state.status = checkboxState
		}
	}
	questions = [
		{
			method: 'askForCountyByMap',
			mode: "4fromMap",
			text: "Which county is highlighted on the map?"
		},
		{
			method: 'askForMapPickByCounty',
			mode: "pickOnMap",
			text: "Please pick County $[[county]] on the map."
		},
		{
			method: 'askForCountyByProvince',
			mode: "4textOnly",
			text: "Which county is in $[[province]]?"
		},
		{
			method: 'askForCountyByIrishName',
			mode: "4textOnly",
			text: "Which county's Irish name is $[[name]]?"
		},
		{
			method: 'askForCountyByBorderingItem',
			mode: "4textOnly",
			text: "Which county is $[[someDirection]] of $[[somePlace]]?"
		},
		{
			method: 'askForCountyByCity',
			mode: "4textOnly",
			text: "Which county has $[[city]] in it?"
		},
		{
			method: 'askForCountyByAttraction',
			mode: "4textOnly",
			text: "Which county is known for $[[an_attraction_or_attribute]]?"
		},
		// {
		// 	method: 'askForCountyByAttribute',
		// 	mode: "4textOnly",
		// },
		// {
		// 	method: askForCountyByMap,
		// 	mode: "4fromMap",
		// 	text: "Which county is highlighted on the map?"
		// },
	]
	
	// --------------- FUNCTIONAL HELPER METHODS -------------------------------
	
	shuffle = arr => {
		let ret = []
		while (arr.length > 0) {
			let i = Math.floor(Math.random() * arr.length)
			ret.push(arr.splice(i, 1)[0])
		}
		return ret
	}
	
	expand = (str, vars) => {
		return str.replace(/\$\[\[([a-z_]+)\]\]/gi, (x, m) => {
			return vars[m]
		})
	}
	
	expandAttractions = str => {
		if (str.substr(0,3) === 'its') {
			return str
		}
		if (str.search(/^[a-z]+ing /) !== -1) {
			return str
		}
		return "having " + str + " in it"
	}
	
	oppositeDirection = whichWay => {
		switch (whichWay) {
			case 'north':
				return "south"
			case 'south':
				return "north"
			case 'east':
				return "west"
			case 'west':
				return "east"
			case 'northwest':
				return "southeast"
			case 'northeast':
				return "southwest"
			case 'southwest':
				return "northeast"
			case 'southeast':
				return "northwest"
			default:
				console.log("You called oppositeDirection() with argument '" + whichWay + "', but I don't know how to answer that. I can only do the 8 cardinal and diagonal directions.");
				return false
		}
	}
	
	ensureQuestionOrder = (correct, others) => {
		let ret = this.shuffle(others.map(i => {return {text: i}}).concat({text: correct, state: "correct"}))
		while (ret[this.state.prevIndex].text === this.state.prevCounty) {
			ret = this.shuffle(ret)
		}
		return ret
	}
	
	getAttractions = restrict => {
		let ret = []
		if (typeof restrict === 'string') {
			countyData.forEach(county => {
				if (county.name !== restrict) {
					return true
				}
				county.knownFor.forEach(attraction => {
					ret.push(attraction)
				})
			})
		} else if (typeof restrict === 'object') {
			countyData.forEach(county => {
				if (restrict[county.province]) {
					county.knownFor.forEach(attraction => {
						ret.push(attraction)
					})
				}
			})
		} else {
			countyData.forEach(county => {
				county.knownFor.forEach(attraction => {
					ret.push(attraction)
				})
			})
		}
		return ret
	}
	getCities = restrict => {
		let ret = []
		if (typeof restrict === 'string') {
			countyData.forEach(county => {
				if (county.name !== restrict) {
					return true
				}
				county.majorCities.forEach(city => {
					ret.push(city)
				})
			})
		} else if (typeof restrict === 'object') {
			countyData.forEach(county => {
				if (restrict[county.province]) {
					county.majorCities.forEach(city => {
						ret.push(city)
					})
				}
			})
		} else {
			countyData.forEach(county => {
				county.majorCities.forEach(city => {
					ret.push(city)
				})
			})
		}
		return ret
	}
	getCounties = (provinces = {Connaught: true, Leinster: true, Munster: true, Ulster: true}) => {
		let arr = []
		Object.keys(provinces).forEach(k => {if (provinces[k]) {arr.push(k)}})
		return countyData.filter(c => arr.includes(c.province)).map(c => c.name)
	}
	getCountiesFromProvinces = (arr = ["Connaught", "Leinster", "Munster", "Ulster"], omit = '') => {
		if (!(arr instanceof Array)) {
			let orig = arr
			arr = []
			Object.keys(orig).forEach(k => {if (orig[k]) {arr.push(k)}})
		}
		return countyData.filter(c => arr.includes(c.province)).map(c => c.name).filter(n => n !== omit)
	}
	getCountiesThatArent = (county, provinces = {Connaught: true, Leinster: true, Munster: true, Ulster: true}, howMany = 3) => {
		let filterFunc
		if (typeof county === 'string') {
			filterFunc = c => c !== county
		} else if (county instanceof Array) {
			filterFunc = c => !county.includes(c)
		}
		return this.shuffle(this.getCounties(provinces).filter(filterFunc)).slice(0, howMany)
	}
	getCountiesThatArentInProvince = (province, howMany = 3) => {
		return this.shuffle(countyData.filter(c => c.province !== province).map(c => c.name)).slice(0, howMany)
	}
	getCountiesThatDontBorder = (place, howMany = 3) => {
		place = place.replace(/^County /, '')
		let items = countyData.filter(data => {
			if (data.name === place) {
				return false
			}
			if (data.borderedBy.map(i => i.name.replace(/^County /, '')).includes(place)) {
				return false
			}
			return true
		}).map(c => c.name)
		return this.shuffle(items).slice(0, howMany)
	}
	getCountyData = name => {
		return countyData.find(county => county.name === name)
	}
	pickOneOf = arr => {
		return arr[Math.floor(Math.random() * arr.length)]
	}
	
	// ------------------------ UI DISPLAY METHODS -----------------------------
	
	showTimer = null
	hideTimer = null
	
	showMessage = (str, color) => {
		this.finishHideMessage()
		clearTimeout(this.showTimer)
		clearTimeout(this.hideTimer)
		this.setState({message: {text: str, type: color + ' show'}})
		this.showTimer = setTimeout(this.hideMessage.bind(this), 1250)
	}
	hideMessage = () => {
		let messageState = this.state.message
		messageState.type = messageState.type.replace(/\bshow\b/, 'hide')
		this.setState({message: messageState})
		this.hideTimer = setTimeout(this.finishHideMessage.bind(this), 1250)
	}
	finishHideMessage = () => {
		this.setState({message: {type: ''}})
	}
	describeElement = elem => {
		let el = []
		el.push(elem.tagName.toUpperCase())
		if (elem.id) el.push("#" + elem.id)
		if (elem.class) el.push("." + elem.class)
		return el.join(' ')
	}
	toggleMode = () => {
		if (this.state.mode === "study") {
			this.setState({mode: "quiz", buttonText: "Study Counties"})
			this.askNextQuestion()
		} else {
			this.setState({
				mode: "study",
				questionMode: "",
				buttonText: "Start Quiz",
				selectedCounty: {
					name: 'all',
					color: '',
				},
				question: "",
				listItems: []
			})
		}
	}
	
	// ---------------------- CLICK HANDLING METHODS ---------------------------
	
	handleMapClick = evt => {
		evt.preventDefault()
		if (this.state.questionMode !== "pickOnMap") {
			return
		}
		let str = evt.target.id
		let classes = evt.target.className
		let color = "red"
		let county = str.replace(/^area-/,'')
		let fullCounty = this.getCountyData(county)
		if (! this.state.status.provinces[fullCounty.province]) {
			this.showMessage("Sorry, " + county + "'s in " + fullCounty.province + ".", "invalid")
			return
		}
		if (classes.search(/correct/) !== -1) {
			color = "green"
			this.showMessage("Correct, that's " + county + "!", "correct")
			setTimeout(this.askNextQuestion.bind(this), 1600)
		} else {
			this.showMessage("Sorry, that's " + county + ".", "wrong")
		}
		this.setState({selectedCounty: {name: county, color: color}})
	}
	handleModeSelectorCheckboxClick = evt => {
		const val = evt.target.checked
		let provinces = this.state.status.provinces
		let questions = this.state.status.questions
		if (evt.target.id.match(/^province-/)) {
			const province = evt.target.id.replace(/^province-/, '')
			provinces[province] = val
		} else {
			const question = evt.target.id.replace(/^question-/, '')
			questions[question] = val
		}
		this.setState({status: {provinces: provinces, questions: questions}})
		localStorage.setItem("checkboxState", JSON.stringify(this.state.status))
	}
	handlePickListClick = evt => {
		evt.preventDefault()
		let str = evt.target.id
		let classes = evt.target.className
		let county = str.replace(/^pick-/,'')
		if (classes.search(/correct/) !== -1) {
			if (this.state.questionMode === '4textOnly') {
				this.showMessage(county + " is correct!", "correct")
				// evt.target.parentNode.className += " focus"
			} else {
				this.showMessage("Correct, that's " + county + "!", "correct")
			}
			setTimeout(this.askNextQuestion.bind(this), 1750)
		} else {
			evt.target.className = "wrong"
		}
	}
	
	// ------------------ QUESTION TYPE METHODS --------------------------------
	
	askNextQuestion = () => {
		const opts = Object.keys(this.state.status.questions).filter(q => !!(this.state.status.questions[q]))
		let rand = Math.round(Math.random() * 100)
		rand = rand % opts.length
		this[opts[rand]]()
	}
	
	askForCountyByAttraction = () => {
		const attraction = this.pickOneOf(this.getAttractions(this.state.status.provinces))
		const county = countyData.find(c => c.knownFor.includes(attraction)).name
		const others = this.getCountiesThatArent(county, this.state.status.provinces)
		const questionList = this.ensureQuestionOrder(county, others)
		const q = this.questions.find(i => i.method === 'askForCountyByAttraction')
		this.setState({
			selectedCounty: {name: "hide"},
			prevCounty: county,
			prevIndex: questionList.findIndex(i => i.text === county),
			question: this.expand(q.text, {an_attraction_or_attribute: this.expandAttractions(attraction)}),
			questionMode: q.mode,
			listItems: questionList
		})
	}
	
	askForCountyByCity = () => {
		const city = this.pickOneOf(this.getCities(this.state.status.provinces))
		const county = countyData.find(c => c.majorCities.includes(city)).name
		const others = this.getCountiesThatArent(county, this.state.status.provinces)
		const questionList = this.ensureQuestionOrder(county, others)
		const q = this.questions.find(i => i.method === 'askForCountyByCity')
		this.setState({
			selectedCounty: {name: "hide"},
			prevCounty: county,
			prevIndex: questionList.findIndex(i => i.text === county),
			question: this.expand(q.text, {city}),
			questionMode: q.mode,
			listItems: questionList
		})
	}
	
	askForCountyByMap = county => {
		if (!county) {
			county = this.pickOneOf(this.getCountiesFromProvinces(this.state.status.provinces, this.state.prevCounty))
		}
		const others = this.getCountiesThatArent(county, this.state.status.provinces)
		const questionList = this.ensureQuestionOrder(county, others)
		const q = this.questions.find(i => i.method === 'askForCountyByMap')
		this.setState({
			selectedCounty: {name: county, color: "green"},
			prevCounty: county,
			prevIndex: questionList.findIndex(i => i.text === county),
			question: q.text,
			questionMode: q.mode,
			listItems: questionList
		})
	}
	
	askForCountyByIrishName = county => {
		if (!county) {
			county = this.pickOneOf(this.getCountiesFromProvinces(this.state.status.provinces, this.state.prevCounty))
		}
		const others = this.getCountiesThatArent(county, this.state.status.provinces)
		const questionList = this.ensureQuestionOrder(county, others)
		const irishName = this.getCountyData(county).irishName
		const q = this.questions.find(i => i.method === 'askForCountyByIrishName')
		this.setState({
			selectedCounty: {name: "hide"},
			prevCounty: county,
			prevIndex: questionList.findIndex(i => i.text === county),
			question: this.expand(q.text, {name: irishName}),
			questionMode: q.mode,
			listItems: questionList
		})
	}
	
	askForCountyByBorderingItem = county => {
		if (!county) {
			county = this.pickOneOf(this.getCountiesFromProvinces(this.state.status.provinces, this.state.prevCounty))
		}
		const otherPlace = this.pickOneOf(this.getCountyData(county).borderedBy)
		const direction = this.oppositeDirection(otherPlace.direction)
		const somePlace = otherPlace.name
		const others = this.getCountiesThatDontBorder(somePlace)
		const questionList = this.ensureQuestionOrder(county, others)
		const q = this.questions.find(i => i.method === 'askForCountyByBorderingItem')
		this.setState({
			selectedCounty: {name: "hide"},
			prevCounty: county,
			prevIndex: questionList.findIndex(i => i.text === county),
			question: this.expand(q.text, {someDirection: direction, somePlace: otherPlace.name}),
			questionMode: q.mode,
			listItems: questionList
		})
	}
	
	askForCountyByProvince = county => {
		if (!county) {
			county = this.pickOneOf(this.getCountiesFromProvinces(this.state.status.provinces, this.state.prevCounty))
		}
		const province = this.getCountyData(county).province
		const others = this.getCountiesThatArentInProvince(province)
		const questionList = this.ensureQuestionOrder(county, others)
		const q = this.questions.find(i => i.method === 'askForCountyByProvince')
		this.setState({
			selectedCounty: {name: "hide"},
			prevCounty: county,
			prevIndex: questionList.findIndex(i => i.text === county),
			question: this.expand(q.text, {province}),
			questionMode: q.mode,
			listItems: questionList
		})
	}
	
	askForMapPickByCounty = county => {
		if (!county) {
			county = this.pickOneOf(this.getCountiesFromProvinces(this.state.status.provinces, this.state.prevCounty))
		}
		const q = this.questions.find(i => i.method === 'askForMapPickByCounty')
		this.setState({
			selectedCounty: {name: "none"},
			prevCounty: county,
			targetCounty: county,
			question: this.expand(q.text, {county}),
			questionMode: q.mode,
			listItems: []
		})
	}
	
	render() {
		return (
			<div className={"App " + this.state.mode}>
				<MapOfIreland county={this.state.selectedCounty} correct={this.state.targetCounty} handleClick={e => this.handleMapClick(e)} />
				<div id="Question">{this.state.question}</div>
				
				<PickList state={this.state} handleClick={e => this.handlePickListClick(e)} />
				<HelpModule />
				<ModeSelector state={this.state} questionStructure={this.questions} handleCheckboxClick={e => this.handleModeSelectorCheckboxClick(e)} handleButtonClick={this.toggleMode} />
			</div>
		);
	}
}

export default App;
